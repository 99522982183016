<template>
  <v-dialog v-model="dialog" width="600">
    <v-card v-if="dialog">
      <v-card-title>
        {{ $t('components.premium-dialog.need-premium-title') }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t('components.premium-dialog.text') }}
        </p>

        <PremiumForm />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '../eventBus'

import PremiumForm from '@/components/PremiumForm'

export default {
  components: {
    PremiumForm,
  },

  data() {
    return {
      dialog: false,
    }
  },

  created() {
    this.listenActivator()
  },

  methods: {
    listenActivator() {
      EventBus.$on('openPremiumDialog', () => (this.dialog = true))
    },
  },
}
</script>
